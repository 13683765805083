import { IAlert } from "../../../shared/components/CustomAlert";
import { GridSetup, IRole, ReportDetail } from "../models/role-model";



export const ROLE_FORM_DEFAULT: IRole = {
    id: '',
    name: '',
    displayId: 0,
    
}

export const Initial_GridSetup: GridSetup = {
    sort: [{ field: 'id', dir: 'desc' }],
    skip: 0,
    take: 10,
    filter: {
        logic: "and",
        filters: [],
    }
}


export const TOASTER_DEFAULT: IAlert = {
    message: ``,
    header: ``,
    type: 'success'
}

export enum REPORT_NAMES {
    Report1 = "Executive Summary",
    Report2 = "Health & Safety",
    Report3 = "Risk",
    Report4 = "Cost",
    Report5 = "Schedule",
    Report6 = "People",
    Report7 = "Progress"
}

export enum PAGE_PERMISSION {
  Users = "Administration.Users",
  Roles = "Administration.Roles",
  Report_Sample1 = `Report.Executive Summary`,
  Report_Sample2 = `Report.Health & Safety`,
  Report_Sample3 = `Report.Risk`,
  Report_Sample4 = `Report.Cost`,
  Report_Sample5 = `Report.Schedule`,
  Report_Sample6 = `Report.People`,
  Report_Sample7 = `Report.Progress`
}

export type ReportProps = {
    id?: string;
    page?: string;
    path: string;
    label?: string;
}

export const SAMPLE_REPORT1: ReportDetail = {
    id: '38794092-ca11-4890-b25a-851f6a64dfab', 
    page: '69707b20e73eb6198ec5',
    name: REPORT_NAMES.Report1, 
    permission: PAGE_PERMISSION.Report_Sample1, 
    icon: '/media/icons/tims/sitemap.svg',
    route: `/report/${REPORT_NAMES.Report1}`
}

export const SAMPLE_REPORT2: ReportDetail = {
    id: '38794092-ca11-4890-b25a-851f6a64dfab', 
    page: '06bf663977550df4f07c',
    name: REPORT_NAMES.Report2, 
    permission: PAGE_PERMISSION.Report_Sample2, 
    icon: '/media/icons/tims/helmet-safety.svg',
    route: `/report/${REPORT_NAMES.Report2}`
}

export const SAMPLE_REPORT3: ReportDetail = {
    id: '38794092-ca11-4890-b25a-851f6a64dfab', 
    page: 'd9afa7d6521ca9fd4319',
    name: REPORT_NAMES.Report3, 
    permission: PAGE_PERMISSION.Report_Sample3, 
    icon: '/media/icons/tims/triangle-exclamation.svg',
    route: `/report/${REPORT_NAMES.Report3}`
}

export const SAMPLE_REPORT4: ReportDetail = {
    id: '38794092-ca11-4890-b25a-851f6a64dfab', 
    page: '095582324ed80a423a2f',
    name: REPORT_NAMES.Report4, 
    permission: PAGE_PERMISSION.Report_Sample4, 
    icon: '/media/icons/tims/money-bill.svg',
    route: `/report/${REPORT_NAMES.Report4}`
}

export const SAMPLE_REPORT5: ReportDetail = {
    id: '38794092-ca11-4890-b25a-851f6a64dfab', 
    page: '3813da957a80237bf8b8',
    name: REPORT_NAMES.Report5, 
    permission: PAGE_PERMISSION.Report_Sample5, 
    icon: '/media/icons/tims/bars-progress.svg',
    route: `/report/${REPORT_NAMES.Report5}`
}

export const SAMPLE_REPORT6: ReportDetail = {
    id: '38794092-ca11-4890-b25a-851f6a64dfab', 
    page: '9837160645c5438dbee7',
    name: REPORT_NAMES.Report6, 
    permission: PAGE_PERMISSION.Report_Sample6, 
    icon: '/media/icons/tims/people-group.svg',
    route: `/report/${REPORT_NAMES.Report6}`
}

export const SAMPLE_REPORT7: ReportDetail = {
    id: '38794092-ca11-4890-b25a-851f6a64dfab', 
    page: '81d417f48dd0d0e53854',
    name: REPORT_NAMES.Report7, 
    permission: PAGE_PERMISSION.Report_Sample7, 
    icon: '/media/icons/tims/list-check.svg',
    route: `/report/${REPORT_NAMES.Report7}`
}

export const SAMPLE_REPORTS : ReportDetail[] = [
    SAMPLE_REPORT1,
    SAMPLE_REPORT2,
    SAMPLE_REPORT3,
    SAMPLE_REPORT4,
    SAMPLE_REPORT5,
    SAMPLE_REPORT6,
    SAMPLE_REPORT7,
]