import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Page } from '../modules/roles/models/role-model';
import { PAGE_PERMISSION } from '../modules/roles/constant/role-default';

type Props = {
  component: React.ComponentType<any>;
  id?: string;
  page?: string;
  path: string;
  exact?: boolean;
  permission: PAGE_PERMISSION,
  activePages: Page[];
  label?: string;
};

const ActivatedPageRoute: React.FC<Props> = ({
  id: id,
  page: page,
  component: Component,
  permission,
  activePages,
  label,
  path,
  ...rest
}) => {

  return (
    <Route
      {...rest}
      render={props => {
        var allowed = activePages.some(page => page.name === permission);
        return allowed ? (
          // <span>{JSON.stringify(props)}</span>
          <Component id={id} page={page} path={props.match.url} label={label} {...props} />
        ) : (
          <Redirect to='/error/404' />
        );
      }}
    />
  );
};

export default ActivatedPageRoute;
