import React, {Suspense, lazy} from 'react'
import {Redirect, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import ActivatedPageRoute from './ActivatedPageRoute'
import { PAGE_PERMISSION, ReportProps, SAMPLE_REPORTS } from '../modules/roles/constant/role-default'
import { Page, ReportDetail } from '../modules/roles/models/role-model'
import ActivePageBasedRedirect from './ActivePageBasedRedirect'

type Props = {
  activePages: Page[]
};

const PrivateRoutes: React.FC<Props> = ({ activePages = [] }) => {

  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  const UsersPage = lazy<React.FC>(() => import('../modules/users/UserPage'))
  const RolesPage = lazy<React.FC>(() => import('../modules/roles/RolePage'))
  
  const ReportPage = lazy<React.FC<ReportProps>>(() => import('../modules/reports/components/ReportPage'))
    
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <ActivatedPageRoute
          path='/user'
          component={UsersPage}
          permission={PAGE_PERMISSION.Users}
          activePages={activePages} />

        <ActivatedPageRoute
          path='/role'
          component={RolesPage}
          permission={PAGE_PERMISSION.Roles}
          activePages={activePages} />
          
        {
          SAMPLE_REPORTS.map((x: ReportDetail) => {
            return <ActivatedPageRoute
            id={x.id}
            page={x.page}
            key={x.name}
            label={x.name}
            path={'/report/' + x.name}
            component={ReportPage}
            permission={x.permission}
            activePages={activePages} />
          })
        }
        <ActivePageBasedRedirect></ActivePageBasedRedirect>
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}

export { PrivateRoutes };