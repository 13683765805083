import React from 'react';
import { Redirect } from 'react-router-dom';
import { usePageData } from '../../_metronic/layout/core';
import { PAGE_PERMISSION, SAMPLE_REPORTS } from '../modules/roles/constant/role-default';
import { ReportDetail } from '../modules/roles/models/role-model';

const ActivePageBasedRedirect = () => {

  const { currentUser: { activePages } } = usePageData();

  if (!activePages) {
    return null;
  }

  const reportOrders = SAMPLE_REPORTS.map((x: ReportDetail) => {
    return {permission: x.permission, route: x.route}
  })
  const permissionsOrder = [
    ...reportOrders,
    { permission: PAGE_PERMISSION.Users, route: '/user' },
    { permission: PAGE_PERMISSION.Roles, route: '/role' }
  ];

  for (let { permission, route } of permissionsOrder) {
    console.log(permission)
    if (activePages.some(page => page.name === permission)) {
      return <Redirect to={route} />;
    }
  }

  return null;

};

export default ActivePageBasedRedirect;
