/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {LoginPage} from './components/LoginPage'

import '../../shared/styles/app.css';
import { ResetPassword } from './components/ResetPassword'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='container-fluid h-100 p-0'>
      <div className='row h-100 m-0'>
        <div className='col-md-4 d-flex flex-column align-items-center justify-content-center'>
          <div className='w-lg-500px p-10 p-lg-15 mx-auto' style={{ maxWidth: '100%' }}>
            <div className='text-center mb-12'>
              <a href='#'>
                <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-4.png')} className='h-100px' />
              </a>
            </div>
              <Switch>
                <Route path='/auth/login' component={LoginPage} />
                <Route path='/auth/registration' component={Registration} />
                <Route path='/auth/forgot-password' component={ForgotPassword} />
                <Route path='/auth/reset-password' exact component={ResetPassword} />
                <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch>
          </div>
        </div>

        <div className='col-md-8 d-none d-md-flex justify-content-center align-items-center bgi-position-y-center position-x-center bgi-size-contain'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/logos/banner.png')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
          }}>
        </div>
      </div>
    </div>
    
  )
}
