/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { usePageData } from '../../core'
import { PAGE_PERMISSION, SAMPLE_REPORTS } from '../../../../app/modules/roles/constant/role-default'
import { ReportDetail } from '../../../../app/modules/roles/models/role-model'

export function AsideMenuMain() {
  const intl = useIntl()
  const [isAdministrator, setIsAdministrator] = useState(false);
  const { currentUser } = usePageData();

  useEffect(() => {
    setIsAdministrator(currentUser.userRoles.includes('Administrator'))
  }, [currentUser.userRoles])

  return (
    <>
	{
	  SAMPLE_REPORTS.map((x: ReportDetail) => {
		return <AsideMenuItem
		  key={x.name}
          to={x.route}
          title={x.name + ''}
          fontIcon='bi-chat-left'
          icon={x.icon + ''}
          pagePermission={x.permission}
        />
	  })
	}
	
	<div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
    </div>
	
	<AsideMenuItemWithSub to='/'
        title="Administration"
        fontIcon='bi-chat-left'
        icon='/media/icons/tims/menu-administration.svg'
        pagePermissions={[
          PAGE_PERMISSION.Users,
          PAGE_PERMISSION.Roles
        ]}
      >
		<AsideMenuItem
          to='/user'
          title={intl.formatMessage({id: 'MENU.USERS'})}
          fontIcon='bi-chat-left'
          icon='/media/icons/tims/menu-users.svg'
          pagePermission={PAGE_PERMISSION.Users}
		/>
		
		<AsideMenuItem
          to='/role'
          title={intl.formatMessage({id: 'MENU.ROLES'})}
          fontIcon='bi-chat-left'
          icon='/media/icons/tims/menu-roles.svg'
          pagePermission={PAGE_PERMISSION.Roles}
		/>
	</AsideMenuItemWithSub>
    </>
  )
}
